<template>
    <div>
        <div v-if="packagesfetchState.started" class="bg-white flex justify-center items-center py-10 shadow rounded-lg ring-2-gray-500">
            <div class="flex items-center justify-center gap-6 flex-col">
                <Loader />
                Fetching packages...
            </div>
        </div>

        <div v-if="packagesfetchState.has_data">
            <sub-header :showSearchBar="true" :handleClick="createPackage" :handleSearchFilter="handleSearchFilter" buttonText="Create package" :showBtn="true" />
            <div class="grid md:grid-cols-2 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-1 items-stretch justify-items-stretch gap-4">
                <div v-for="tenant_package in filterPackages" :key="tenant_package.id">
                    <packageCard class="h-full" :edit="true" :tenant_package="tenant_package" :progress="true" @showCloneModal="showCloneModal" />
                </div>

                <div class="w-full flex bg-white shadow rounded-xl py-10 justify-center" v-if="isLoading">
                    <div class="flex items-center justify-center gap-6 flex-col">
                        <DelviumLoader />
                        <div class="">Loading</div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="packagesfetchState.no_data">
            <div class="bg-white flex justify-center items-center h-80 rounded-lg ring-2-gray-500">
                <div class="flex flex-col justify-center items-center">
                    <div>You do not have any active packages yet.</div>
                    <div class="mt-8">
                        <button @click="createPackage" class="inline-block px-6 py-2.5 pl-5 pr-5 h-8 text-white-text font-medium text-xs leading-tight rounded-xl shadow-md hover:bg-blue-700 hover:shadow-lg capitalize focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out" style="background: #0d69d5">Create package</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Clone Popop -->
        <modalContent ref="clone-package-modal" :max-width="370" height="75%" name="clonePackage" title="Clone Package" @close="closeModal" :showFooter="false">
            <template #content>
                <div class="flex-1" v-if="selectedPackage">
                    <div class="flex flex-col flex-wrap max-w-xs">
                        <span class="text-gray-500 text-md"> Package name </span>
                        <Input v-model="selectedPackage.package_name" placeholder="Package name" name="package_name"> </Input>
                        <div class="py-2"></div>
                        <span class="text-gray-500 text-md"> Package label </span>
                        <Input v-model="selectedPackage.package_name_internal" placeholder="Package label" name="package_label"> </Input>
                        <div class="py-2"></div>
                        <span class="text-gray-500 text-md"> Package description </span>
                        <Input v-model="selectedPackage.package_description" placeholder="Package description" name="package_description"> </Input>
                        <div class="py-2"></div>
                        <span role="button" class="px-4 py-2 h-10 w-24 font-medium bg-gray-200 text-gray-500 cursor-pointer rounded-md ml-auto text-center" @click="handleCloneModal()"> Clone </span>
                    </div>
                </div>
            </template>
        </modalContent>
    </div>
</template>
<script>
import packageCard from "@/components/package-card";
import axios from "@/axios";
import SubHeader from "@/components/SubHeader";
import Loader from "@shared/loader";
import DelviumLoader from "@shared/loader";
import modalContent from "@/components/modal-content";
import Input from "@/components/input";
import { cloneDeep } from "lodash";

export default {
    name: "create-case",
    components: {
        packageCard,
        SubHeader,
        Loader,
        DelviumLoader,
        modalContent,
        Input,
    },
    title: "Packages",
    async mounted() {
        await this.fetchTenantPackages();
    },
    data() {
        return {
            packagesfetchState: {
                started: true,
                nodata: false,
                has_data: false,
            },
            searchKeywords: "",
            isLoading: false,
            packages: [],
            selectedPackage: null,
        };
    },
    computed: {
        filterPackages() {
            return (this.packages && this.packages.length) > 0 ? this.packages.filter((pkg) => pkg?.package_name?.toLowerCase().includes(this.searchKeywords.toLowerCase()) || pkg?.package_description?.toLowerCase().includes(this.searchKeywords.toLowerCase())) : [];
        },
    },
    methods: {
        async fetchTenantPackages() {
            // this.isLoading = true

            const data = await axios.get(`/screening-package`);
            this.packages = data.data;
            if (data.data.length >= 1) {
                this.packagesfetchState.has_data = true;
                this.packagesfetchState.started = false;
            } else {
                this.packagesfetchState.no_data = true;
                this.packagesfetchState.started = false;
            }
        },
        createPackage() {
            this.$router.push({
                path: "/create-package",
            });
        },

        handleSearchFilter(val) {
            this.searchKeywords = val;
        },

        showCloneModal(tenant_package) {
            this.selectedPackage = cloneDeep(tenant_package);
            this.$refs["clone-package-modal"].showModal();
        },
        closeModal() {
            this.selectedPackage = null;
            this.$refs["clone-package-modal"].hideModal();
        },
        async handleCloneModal() {
            this.isLoading = true;
            try {
                const pkg = await axios.post(`screening-package/${this.selectedPackage?.id}/clone`, {
                    package_name: this.selectedPackage?.package_name,
                    package_name_internal: this.selectedPackage?.package_name_internal,
                    package_description: this.selectedPackage?.package_description,
                });
                if (pkg?.data?.package_id) {
                    this.fetchTenantPackages();
                    this.$toast.success(pkg?.data?.message || "Package cloned successfully !");
                }
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
            }
            this.closeModal();
            this.isLoading = false;
        },
    },
};
</script>
<style scoped>
.empty-package--content-area {
    border: dashed 2px #cfcfcf;
    margin: 0 auto;
}
</style>
